


































































































import { reqArticleList } from "@/api";
import { Component, Vue, Watch } from "vue-property-decorator";
import { readNewsDetailHandler, transformNewsString } from "@/utils";

@Component({
  name: "news"
})
export default class extends Vue {
  news: any[] = [];
  loading = false;
  page = 1;
  size = 15;
  childNews: any[] = [];
  childLoading = false;
  childPage = 1;
  childSize = 15;
  children: any = {
    rumours: [
      { name: "辟谣专区", to: "/rumours" },
      { name: "川渝联合辟谣", to: "/unionRumours" }
    ],
    standard: [
      { name: "法律", to: "/standard" },
      { name: "行政法规", to: "/administer" },
      { name: "部门规章", to: "/department" },
      { name: "司法解释", to: "/judicial" },
      { name: "规范性文件", to: "/normative" },
      { name: "政策文件", to: "/policy" }
    ]
  };

  get paginationNews() {
    const { page, size } = this;
    return this.news.slice((page - 1) * size, page * size);
  }

  get paginationChildNews() {
    const { childPage, childSize } = this;
    return this.childNews.slice(
      (childPage - 1) * childSize,
      childPage * childSize
    );
  }

  get columnId() {
    return this.$route.meta.column ?? "";
  }

  get childColumnId() {
    return this.$route.meta.column_child ?? "";
  }

  readDetailHandler(item: any) {
    readNewsDetailHandler(item);
  }

  @Watch("columnId", { immediate: true })
  async initNewsList() {
    this.size = 15;
    this.childSize = 15;
    try {
      this.loading = true;
      const { data } = await reqArticleList(this.columnId);
      this.news = transformNewsString(data);
    } catch (error) {
      //
    } finally {
      this.loading = false;
    }
    if (!this.childColumnId) return;
    try {
      this.childLoading = true;
      const { data } = await reqArticleList(this.childColumnId);
      this.childNews = transformNewsString(data);
    } catch (error) {
      //
    } finally {
      this.childLoading = false;
    }
  }
}
